import React from "react"

import { Link } from "../app/atoms/link"
import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SecondPage = ({ data }) => {

  const { image } = data;

  return (
    <Layout>
      <Seo title="Page two" />
      <div className="container">
        <div className="row">
          <div className="col-12 py-5">
            <h1>Hi from the second page</h1>
            <p>
              <GatsbyImage image={getImage(image.childImageSharp)} />
            </p>
            <Link to="/">Go back to the homepage</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondPage;

export const query = graphql`query {
  image: file(name: {eq: "chisiamo_apertura"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        layout: CONSTRAINED
        quality: 60
        placeholder: TRACED_SVG
      )
    }
  }
}`;
